import React from "react"
import { navigate } from "gatsby"
import {
  ComposableMap,
  ZoomableGlobe,
  Geographies,
  Geography,
  Markers,
  Marker,
  Graticule,
} from "react-simple-maps"

const mapStyles = {
  width: "100%",
  height: "auto",
  backgroundColor: "#FBF7F2", 
  borderRadius: "50%",
  boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.1)"
}

const Chart = ({ projects, setTooltipContent }) => {
  
  projects.map(d => {
    console.log("Project: ", d.location)
  })
  
  return (
    <div data-tip="">
      <ComposableMap
        width={500}
        height={500}
        projection="orthographic"
        projectionConfig={{ scale: 250 }}
        style={mapStyles}
      >
        <ZoomableGlobe center={[-20, 25]}>
          <circle cx={250} cy={250} r={250} fill="#fff" stroke="#FBF7F2" />
          <Graticule globe stroke="#F4F0EB" />
          <Geographies
            disableOptimization
            geography="https://unpkg.com/world-atlas@1.1.4/world/110m.json"
          >
            {(geos, proj) =>
              geos.map((geo, i) => (
                <Geography
                  key={geo.id + i}
                  geography={geo}
                  projection={proj}
                  style={{
                    default: { fill: "#EAE6E1" },
                    hover: { fill: "#EAE6E1" },
                    focus: { fill: "#EAE6E1" },
                    pressed: { fill: "#EAE6E1" },
                  }}
                />
              ))
            }
          </Geographies>

          <Markers>
            {projects.map(({ id, location, slug, title, client, cover }) => (
              <Marker
                key={id}
                marker={{
                  coordinates: [location.longitude, location.latitude],
                }}
                style={{
                  hidden: { display: "none" },
                  default: {
                    fill: "#131C40",
                    stroke: "#fff",
                    strokeWidth: "2",
                  },
                  hover: {
                    pointer: "cursor",
                    fill: "#131C40",
                    stroke: "#131C40",
                    strokeWidth: "2",
                  },
                  pressed: {
                    fill: "#131C40",
                    stroke: "#131C40",
                    strokeWidth: "2",
                  },
                }}
              >
                <circle
                  r={10}
                  onClick={() => navigate(`/case-studies/${slug}`)}
                  onMouseEnter={() => {
                    setTooltipContent({ title, client, cover })
                  }}
                  onMouseLeave={() => {
                    setTooltipContent(null)
                  }}
                />
              </Marker>
            ))}
          </Markers>
        </ZoomableGlobe>
      </ComposableMap>
    </div>
  )
}

export default Chart