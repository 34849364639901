import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { color } from "styled-system"

import Grid from "../Grid"
import Box from "../Box"
import Heading from "../Heading"
import Container from "../Container"
import Link from "../Link"
import Wave from "../Wave"
import Chart from "./Chart"
import RatioContainer from "../RatioContainer"

const Map = ({ projects }) => {
  const [content, setContent] = useState(null)
  return (
    <div>
      <Chart projects={projects} setTooltipContent={setContent} />
      <ReactTooltip>
        {content &&
          <Box maxWidth="24rem" py="0.75rem">
            {/* <RatioContainer style={{ overflow: "hidden" }}>
              <img src={cover && `${cover.url}?w=604&h=403&fit=crop&auto=format`}/>
            </RatioContainer> */}
            <Heading as="h3" fontSize="sm" my="0" mb="0.5rem">{content.title}</Heading>
            <Heading as="h3" fontSize="sm" my="0" fontWeight="500">{content.client}</Heading>
          </Box>
        }
      </ReactTooltip>
    </div>
  )
}

const ProgressBar = styled("div")`
  height: 0.25rem;
  ${color};
  border-radius: 0.5rem;
`

const GlobeSection = () => {
  const globeData = useStaticQuery(graphql`
    query globeQuery {
      allDatoCmsProject {
        nodes {
          id
          title
          slug
          intro
          client
          location {
            latitude
            longitude
          }
          cover {
            alt
            url
          }
        }
      }
    }
  `)

  const projects = globeData.allDatoCmsProject.nodes
  console.log(projects)

  return (
    <>
      <Box position="relative">
        <Box
          position="relative"
          zIndex={2}
          width="100%"
          maxWidth="30rem"
          margin="0 auto"
        >
          <Map projects={projects} />
        </Box>
        <Box position="absolute" top="50%" bottom="0" left="0" right="0">
          <Wave color="lightGrey" variant="top2" height="100%" />
        </Box>
      </Box>
      <Box bg="lightGrey" py="5rem">
        <Container>
          <Grid>
            {projects.slice(0, 6).map(({ id, title, slug, client }) => (
              <Box key={id} gridColumn={["span 12", "span 4"]} mb="2.5rem">
                <ProgressBar bg="yellow" />
                <Link to={`/case-studies/${slug}`}>
                  <Heading
                    as="h3"
                    fontSize={["sm", "md"]}
                    mt="1.25rem"
                    mb="0.5rem"
                  >
                    {title}
                  </Heading>
                  <Heading
                    as="h3"
                    fontSize={["sm", "md"]}
                    my="0"
                    color="darkGrey"
                    fontWeight="500"
                  >
                    {client}
                  </Heading>
                </Link>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
      <Wave color="lightGrey" variant="bottom3" bg="navy" />
    </>
  )
}

export default GlobeSection
