import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../Grid"
import Box from "../Box"

const ClientList = () => {
  const clientListData = useStaticQuery(graphql`
    query clientListQuery {
      allDatoCmsOrganization(sort: { fields: position, order: ASC }) {
        nodes {
          id
          title
          logo {
            url
            alt
          }
        }
      }
    }
  `)

  const organizations = clientListData.allDatoCmsOrganization.nodes

  return (
    <Grid mt="5rem" alignItems="center">
      {organizations.map(({ title, logo, alt, id }) => (
        <Box key={id} gridColumn={["span 6", "span 3"]} mb="2.5rem">
          <img
            src={
              logo.url +
              "?w=240&h=100&fit=fill&fill=solid&fill-color=FFF&auto=format"
            }
            width="100%"
            alt={alt || title}
          />
        </Box>
      ))}
    </Grid>
  )
}

export default ClientList
