import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import TextLoop from "react-text-loop"

import Container from "../components/Container"
import Heading from "../components/Heading"
import P from "../components/Paragraph"
import Box from "../components/Box"
import RatioContainer from "../components/RatioContainer"
import Grid from "../components/Grid"
import Flex from "../components/Flex"
import PageHeader from "../components/PageHeader"
import TeamGrid from "../components/TeamGrid"
import TestimonialSection from "../components/TestimonialSection"
import GlobeSection from "../components/GlobeSection"
import ClientList from "../components/ClientList"
import Button from "../components/Button"
import Link from "../components/Link"
import Wave from "../components/Wave"
import StandaloneLink from "../components/StandaloneLink"
import SeoHelmet from "../components/Seo"

const CustomRatioContainer = styled(RatioContainer)`
  img {
    transition: transform 270ms ease;
    &:hover {
      transform: scale(1.07);
    }
  }
`

const IndexPage = () => {
  const homeData = useStaticQuery(graphql`
    query HomeQuery {
      allDatoCmsProject(sort: { fields: position, order: ASC }) {
        nodes {
          id
          title
          slug
          intro
          client
          cover {
            alt
            url
          }
          location {
            latitude
            longitude
          }
          projectColor {
            hex
          }
        }
      }

      allDatoCmsBlog(sort: { fields: position, order: ASC }) {
        nodes {
          id
          title
          intro
          slug
          cover {
            alt
            url
          }
        }
      }

      allDatoCmsService(sort: { fields: position, order: ASC }) {
        nodes {
          id
          title
          intro
          icon {
            alt
            url
            title
          }
        }
      }
    }
  `)

  const projects = homeData.allDatoCmsProject.nodes
  const services = homeData.allDatoCmsService.nodes
  const blogs = homeData.allDatoCmsBlog.nodes

  return (
    <>
      <SeoHelmet
        description="Data visualisation for the public good"
        url={`http://lapidus.se`}
      />
      <Container pt={["10rem", "12rem"]} size="sm" textAlign="center">
        <Heading
          display={["block", "block", "none"]}
          as="h1"
          fontSize={["xl", "xxl"]}
          letterSpacing={-0.5}
        >
          Data visualisation for the public good
        </Heading>

        <Heading
          display={["none", "none", "block"]}
          as="h1"
          fontSize={["lg", "xl", "xxl"]}
          letterSpacing={-0.5}
        >
          <TextLoop mask={true}>
            <span>Data visualisation</span>
            <span>Open data</span>
            <span>Data storytelling</span>
            <span>Digital strategy</span>
            <span>Machine learning</span>
          </TextLoop>{" "}
          for the public good
        </Heading>
        <P fontSize={["sm", "xl"]} color="darkGrey">
          We understand technology, open data and global development. Working
          with organizations around the world, we implement cutting-edge digital
          solutions.
        </P>

        <P fontSize="md" color="darkGrey" fontStyle="italic" mb="2rem">
          {"We are taking on new projects and growing our team."}
        </P>
        <Button
          variant="primary"
          fontSize="lg"
          as="a"
          href="mailto:hello@lapidus.se"
        >
          {"Get in touch"}
        </Button>
      </Container>
      {/* <PageHeader
        title="Data visualisation for the public good"
        subtitle="We understand technology and global development and work with organizations to conceptualize and implement cutting-edge solutions."
      > */}

      {/* </PageHeader> */}

      <Wave color="lightGrey" mt="5rem" variant="top2" />
      <Box bg="lightGrey">
        <Container mb="0rem" mt="0rem">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading
              as="h2"
              fontSize={["md", "lg"]}
              mr={"3rem"}
              width={["100%", "70%"]}
            >
              {"Featured projects"}
            </Heading>

            <StandaloneLink text={"See all projects"} url={"/case-studies"} />
          </Flex>
          <Grid>
            {projects
              .slice(0, 3)
              .map(({ id, slug, title, client, cover, projectColor }) => (
                <Box
                  key={id}
                  gridColumn={["span 12", null, "span 4"]}
                  mb={["2.5rem", null, 0]}
                >
                  <Link to={`/case-studies/${slug}`}>
                    <CustomRatioContainer
                      ratio={2 / 3}
                      bg={
                        projectColor
                          ? `${projectColor && projectColor.hex}`
                          : "#fff"
                      }
                      style={{ overflow: "hidden", borderRadius: "0.75rem" }}
                    >
                      <img
                        src={
                          cover &&
                          `${cover.url}?w=600&h=400&dpr=2&fit=crop&auto=format`
                        }
                        alt={cover && cover.alt}
                        width={600}
                        height={400}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </CustomRatioContainer>
                    <Heading as="h3" fontSize={["sm", "md"]} my="0.75rem">
                      {title}
                    </Heading>
                    <Heading
                      as="h3"
                      fontSize={["sm", "md"]}
                      my="0.75rem"
                      color="darkGrey"
                      fontWeight="500"
                    >
                      {client}
                    </Heading>
                    {/* <Heading
                      as="h3"
                      fontSize={["sm", "sm"]}
                      my="0.75rem"
                      color="darkGrey"
                      fontWeight="600"
                    >
                      {"Case study"}
                    </Heading> */}
                  </Link>
                </Box>
              ))}
          </Grid>
          <Flex justifyContent="space-between" mt="3rem">
            <Heading as="h2" fontSize={["m", "lg"]} mr={"3rem"}>
              {"Latest news"}
            </Heading>
            <StandaloneLink text={"See all news"} url={"/news"} />
          </Flex>
          <Grid mt="0rem">
            {blogs.slice(0, 4).map(({ id, slug, title, intro, cover }) => (
              <Box
                key={id}
                gridColumn={["span 12", "span 6", null, "span 3"]}
                mb={["2.5rem", null, 0]}
              >
                <Link to={`/news/${slug}`}>
                  <CustomRatioContainer
                    ratio={2 / 3}
                    style={{ overflow: "hidden", borderRadius: "0.75rem" }}
                  >
                    <img
                      src={
                        cover &&
                        cover.url + "?w=600&h=400&dpr=2&fit=crop&auto=format"
                      }
                      alt={cover && cover.alt}
                      width={600}
                      height={400}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </CustomRatioContainer>
                  <Heading as="h3" fontSize={["sm", "md"]} my="0.75rem">
                    {title}
                  </Heading>
                </Link>
              </Box>
            ))}
          </Grid>
        </Container>
      </Box>
      <Wave color="lightGrey" mt="0rem" variant="bottom2" />

      <Container size="sm" my="5rem">
        <Heading as="h2" textAlign="center" fontSize={["lg", "xl"]}>
          {"Who we worked for"}
        </Heading>
        <P textAlign="center" fontSize={["sm", "lg"]} color="darkGrey">
          {
            "Our clients are leading humanitarian organizations, government and statistical agencies, universities, tech innovators and social enterprises who tackle the toughest challenges of our time."
          }
        </P>
        <ClientList />
      </Container>

      <Wave variant="top2" />
      <Box bg="navy">
        <Container py="5rem">
          <TestimonialSection />
        </Container>
      </Box>
      <Wave variant="bottom1" />

      <Container my="5rem" textAlign="center">
        <Heading as="h2" fontSize={["lg", "xl"]} textAlign="center" mb="5rem">
          {"What we do"}
        </Heading>
        <Grid mb="2.5rem">
          {services.slice(0, 6).map(({ id, title, intro, icon }) => (
            <Box
              key={id}
              gridColumn={["span 12", "span 4"]}
              textAlign="center"
              mb="2.5rem"
            >
              <img src={`${icon.url}?auto=format`} alt={icon.title} />
              <Heading as="h3" fontSize={["sm", "lg"]} my="0.75rem">
                {title}
              </Heading>
              <P size="md" color="darkGrey" my="0">
                {intro}
              </P>
            </Box>
          ))}
        </Grid>
        <Flex justifyContent="center">
          <StandaloneLink text={"See all services"} url={"/services"} />
        </Flex>
      </Container>

      <Wave variant="top1" />
      <Box bg="navy">
        <Container py="5rem">
          <Heading
            as="h2"
            fontSize={["lg", "xl"]}
            color="yellow"
            textAlign="center"
            mb="3rem"
          >
            {"Team leadership"}
          </Heading>
          <TeamGrid />
        </Container>
      </Box>
      <Wave variant="bottom1" />

      <Box mt="7rem">
        <Heading as="h2" fontSize={["lg", "xl"]} textAlign="center">
          {"Project locations"}
        </Heading>
        <GlobeSection />
      </Box>
    </>
  )
}

export default IndexPage
