import React from "react"
import styled from "@emotion/styled"

import Flex from "../Flex"
import Link from "../Link"


const CustomLink = styled(Link)`
  > svg {
    transition: all 200ms ease;
  }

  &:hover > svg {
    transform: translateX(0.125rem);
  }
`


const StandaloneLink = ({ text, url }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <CustomLink to={url} variant="standalone" style={{ display: "flex" }}>
        {text}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" style={{ marginLeft: "0.5rem" }}>
          <path d="M5 12h13M12 5l7 7-7 7"/>
        </svg>
      </CustomLink>
    </Flex>
  )
}

export default StandaloneLink
