import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../Grid"
import Box from "../Box"
import Heading from "../Heading"
import P from "../Paragraph"
import Avatar from "../Avatar"

const TestimonialSection = () => {
  const testimonialsData = useStaticQuery(graphql`
    query TestimonialsQuery {
      allDatoCmsTestimonial {
        nodes {
          id
          name
          quote
          title
          role
          image {
            url
            alt
          }
        }
      }
    }
  `)

  const testimonials = testimonialsData.allDatoCmsTestimonial.nodes

  const { id, title, quote, name, role, image } = testimonials[0]
  return (
    <>
      <Grid key={id} alignItems="center">
        <Box gridColumn={["span 12", null, "span 8"]}>
          <Heading as="h2" color="yellow" fontSize={["lg", "xl"]}>
            {title}
          </Heading>
          <P
            fontSize={["lg", "xl"]}
            color="lightGrey"
            fontStyle="italic"
            my="2.5rem"
          >
            {quote}
          </P>
          <P fontSize={["xs", "md"]} color="lightGrey" my="0">
            {name}
          </P>
          <P fontSize={["xs", "md"]} color="lightGrey" my="0">
            {role}
          </P>
        </Box>
        <Box
          gridColumn={["1 / span 6", null, "10 / span 3"]}
          mt={["2.5rem", null, 0]}
          style={{ textAlign: "center" }}
        >
          <Avatar ratio={1}>
            <img
              src={image.url + "?w=240&h=240&dpr=2&fit=crop&auto=format"}
              alt={name}
            />
          </Avatar>
        </Box>
      </Grid>
    </>
  )
}

export default TestimonialSection
